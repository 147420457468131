.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  width: 100%;
  height: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  background: #e8e7e6;
  width: 1px;
  height: 14px;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  width: 100%;
  height: 80px;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}
/*# sourceMappingURL=index.6649edd6.css.map */
